import React, { useEffect, useContext, useState } from 'react';
import { Icon, Divider, Table, List, Card, Image, Label, Button, Grid, Checkbox, Header, Container } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import { bruttoToNetto, changeInitialScale, changePageName, checkisglicko, getCyklPackage, getLimitUsersPackage, packageColor } from '../../../../myfunctions'
import { Markup } from 'interweave';
import tgtl from '../../../../assets/gtl.png';
import { ConnectWS } from '../../../../ConnectWS';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { useToasts } from 'react-toast-notifications';
import BuyProductCompany from '../../SinglePage/Other/BuyProductCompany';
import CallBackTG from '../../../../tools/CallBackTG';
import PackagesAssist from './PackagesAssist';
import pucharpatg from '../../../../assets/pucharpatg.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';



function PackagesGTLCompany(props) {
  const { width } = useWindowDimensions();
  const authContext = useContext(AuthContext);
  const isDemo = useCheckUserIsDemo();
  const { addToast } = useToasts();// eslint-disable-line no-unused-vars

  const [dedicatedWebsites, setdedicatedWebsites] = useState([])
  const [openModalDataPA, setopenModalDataPA] = useState(null);

  const [totalValueV2, settotalValueV2] = useState(null)

  const [chcboxMonth, setchcboxMonth] = useState(false)



  function getPrices() {

    ConnectWS("/generator/getPrices", authContext.token, {}, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        settotalValueV2(response.data)
      } else {
        console.log(response)
      }

    });
  }

  function getCurrentPages() {

    ConnectWS("/dedicated/getDedicatedWebsites", null, {}, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data.length > 0) {
          setdedicatedWebsites(response.data)
        }
      } else {
        console.log(response)
      }

    });
  }


  useEffect(() => {
    getPrices()
    changeInitialScale(0.5)
    changePageName()
    getCurrentPages()
    return () => { changeInitialScale(1) }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  function retCharTab(co) {
    if (co === true) {
      return <Icon color='green' name='checkmark' size='large' />
    } else if (co === false) {
      return <Icon style={{ color: "#cdccc7" }} name='delete' size='large' />
    } else if (co === 0) {
      return "Brak limitu"
    } else if (co === 1) {
      return <Icon style={{ color: "#cdccc7" }} name='ban' size='large' />
    } else {
      return co
    }
  }

  let arrayTab = [
    { body: "<b>Wyniki live</b>  - dostęp do wyników dla zawodników/drużyn i kibiców online", gtlbrown: <>{retCharTab(true)}</>, gtlsilver: <>{retCharTab(true)}</>, gtlgold: <>{retCharTab(true)}</>, gtldiament: retCharTab(true) },
    { body: "Własna podstrona rozgrywek <b>turniej-generator.pl/tg/XXX</b> z wynikami live", gtlbrown: retCharTab(true), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "System turniejowy (indywidualny oraz drużynowy) - drabinki, grupy, każdy z każdym, szwajcarski, brazylijski, (ligowy, tylko w modelu subskrypcji zobacz poniżej)", gtlbrown: retCharTab(true), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "System turniejowy drużynowy - drabinki, grupy, każdy z każdym - <b>dodawanie zawodników do drużyny</b>, dodawanie zaawansowanych statystyk dla zawodników w drużynie", gtlbrown: retCharTab(false), gtlsilver: retCharTab(false), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Ilość zawodników/drużyn biorących udział w danej kategorii", gtlbrown: retCharTab(getLimitUsersPackage(0)), gtlsilver: retCharTab(getLimitUsersPackage(1)), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },
    { body: "Limit rozgrywanych kategorii", gtlbrown: retCharTab(process.env.REACT_APP_IN_LIMITBROWNCAT), gtlsilver: retCharTab(10), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },
    { body: "Limit rozgrywek per tydzień", gtlbrown: retCharTab("1"), gtlsilver: retCharTab(0), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },
    { body: "Możliwość łaczenia kilku rozgrywek w cykl", gtlbrown: retCharTab(false), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Zapisy zawodników/drużyn do rozgrywek online", gtlbrown: retCharTab(false), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Zaawansowane statystyki zawodników/drużyn", gtlbrown: retCharTab(false), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Specjalna strona do wyświetlenia na TV lub rzutniku - wyniki live, automatyczne odświeżane bez potrzeby przeładowania strony", gtlbrown: <>{retCharTab(false)}</>, gtlsilver: <>{retCharTab(true)}</>, gtlgold: <>{retCharTab(true)}</>, gtldiament: retCharTab(true) },

    { body: "Import uczestników do turnieju z pliku csv", gtlbrown: retCharTab(false), gtlsilver: retCharTab(false), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Udostępnij rozgrywki innej osobie posiadającej konto w aplikacji", gtlbrown: retCharTab(false), gtlsilver: retCharTab(false), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },

    { body: "Dostęp do rozegranych, archiwalnych turniejów, lig", gtlbrown: retCharTab("1 ROK"), gtlsilver: retCharTab(0), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },

  ]




  return (
    <>


      <Label color='red' ribbon>
        JEDNORAZOWE
      </Label>



      <Grid >
        <Grid.Row>
          <Grid.Column width={10}>
            {getCyklPackage()}
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={2} textAlign='center'>
            <Image
              alt="puchartg"
              style={{ width: 120, margin: "0 auto" }}
              src={pucharpatg}

            />

          </Grid.Column>
          <Grid.Column stretched width={4} textAlign='center'>

            <Button fluid onClick={() => setopenModalDataPA("1")} color='brown'>Asystent wyboru odpowiedniego pakietu</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>



      <div style={{ marginTop: 20, overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
        <Table striped compact celled unstackable size='small' color="grey">
          <Table.Header>
            <Table.Row >
              <Table.HeaderCell>Funkcjonalność</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "14px" }}> {packageColor(1)}<br /><span style={{ fontSize: 13 }}>Pakiet Brązowy<br />(darmowy)</span></Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2}> {packageColor(2)}<br /><span style={{ fontSize: 13 }}>Pakiet Srebrny</span></Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2}> {packageColor(3)}<br /><span style={{ fontSize: 13 }}>Pakiet Złoty</span></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {arrayTab.map((row, k) => (
              < >
                {k === 8 ?
                  <>
                    <Table.Row >
                      <Table.Cell style={{ lineHeight: "12px" }}>Dla zawodników/drużyn zostanie wygenerowana <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "lictl", name: "Numer TG", value: "" })}>Numer TG</span><br />

                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {retCharTab(false)}
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {retCharTab(true)}
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {retCharTab(true)}
                      </Table.Cell>

                    </Table.Row>
                    <Table.Row >
                      <Table.Cell>Ranking stosowany do określenia mocy przeciwnika/drużyny przeciwnej {checkisglicko() ? <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "GLICKO", name: "Ranking GLICKO", value: "" })}>Ranking GLICKO</span> : <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "RELO", name: "Ranking ELO", value: "" })}>Ranking ELO</span>}  </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {retCharTab(false)}
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {retCharTab(true)}
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {retCharTab(true)}
                      </Table.Cell>

                    </Table.Row>

                  </>
                  : ""
                }
                <Table.Row key={k}>
                  <Table.Cell><Markup content={row.body} /> </Table.Cell>
                  <Table.Cell textAlign='center'>
                    {row.gtlbrown}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    {row.gtlsilver}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    {row.gtlgold}
                  </Table.Cell>

                </Table.Row>
              </>
            ))}

          </Table.Body>
          <Table.Footer style={{ backgroundColor: "#e8e8e8", fontWeight: "bold", fontSize: 15, lineHeight: "13px" }}>
            <Table.Row>
              <Table.Cell>WARTOŚĆ TOKENY-GTL - JEDNORAZOWE</Table.Cell>
              <Table.Cell textAlign='center'>
                0 GTL
              </Table.Cell>
              <Table.Cell textAlign='center'>
                1 GTL
              </Table.Cell>
              <Table.Cell textAlign='center'>
                2 GTL
              </Table.Cell>

            </Table.Row>
          </Table.Footer>

        </Table>
      </div>

      <Grid>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column width={8}>
            <Container textAlign='center' >

              <Icon name='gift' size='huge' color='blue' />

              <Header as='h2' color='blue'>
                14 dni pełnego dostępu – za darmo!
              </Header>


              <p>
                Organizujesz cykliczne turnieje lub planujesz ligę? <br />
                Przetestuj naszą aplikację z pełnym dostępem do wszystkich funkcji przez 14 dni – bez zobowiązań.
              </p>
              <p>
                Skorzystaj z opcji <strong>zamówienia rozmowy z naszym specjalistą</strong> –
                być może otrzymasz jeszcze dłuższy dostęp!<br />
                Albo po prostu przejdź do zakładki <Link to="/kontakt"><strong>Kontakt</strong></Link> i odezwij się do nas.
              </p>
              <CallBackTG isButton={true} />

            </Container>
          </Grid.Column>
          <Grid.Column width={8}>
            <Card.Group style={{ marginTop: 50, marginBottom: 50 }} centered>

              {totalValueV2 && totalValueV2.token ? totalValueV2.token.map((row, i) =>

                <Card style={{ textAlign: "center" }} key={i} className={i === 1 ? "cardpayw" : "cardpay"}>
                  <Card.Content>
                    <Image
                      alt="logo"
                      style={{ width: 70 }}
                      floated='right'
                      src={tgtl}
                    />
                    <Card.Header>TOKEN-GTL - {row.token_amount}</Card.Header>
                    <Card.Meta style={{ fontSize: 15, lineHeight: 1.1, color: "#4B4B4B" }}> Wybierz ten pakiet a otrzymasz <span style={{ fontSize: 18 }}><b>{row.token_amount}</b></span> wirtualnych tokentów GTL</Card.Meta>



                  </Card.Content>
                  <Card.Content >
                    <div style={{ lineHeight: "16px" }}>
                      <span style={{ fontSize: 22, fontWeight: "bold" }}>{bruttoToNetto(row.price)} PLN <span style={{ fontSize: 16 }}>netto</span></span><br />
                      <span style={{ fontSize: 13 }}>{row.price} PLN <span style={{ fontSize: 12 }}>brutto</span></span>
                    </div>
                    <div style={{ lineHeight: "16px", marginTop: 10, fontSize: 11 }}>
                      Do każdej płatności wystawiana jest faktura
                    </div>
                  </Card.Content>

                  <Card.Content extra>
                    <BuyProductCompany payable_service_id={null} buyProductType={1} messageProduct={"Zakup tokenów: " + row.token_amount} isToken={true} isDemo={isDemo} issmall={false} btnname={"WYBIERAM"} price={row.price} />

                  </Card.Content>
                </Card>

              ) : ""}

            </Card.Group>
          </Grid.Column>
        </Grid.Row>

      </Grid>





      <Divider />
      <Label color='red' ribbon>
        MIESIĘCZNE
      </Label>

      <List size='small'>
        <List.Item>
          {packageColor(4)}
          <List.Content><b>Pakiet Diamentowy</b> – dedykowany klubom lub organizacjom, które regularnie organizują cykliczne rozgrywki. Zapewnia pełny dostęp do systemu bez konieczności doładowywania tokenów</List.Content>
        </List.Item>

      </List>
      <div style={{ overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
        <Table striped compact celled unstackable size='small' color="grey">
          <Table.Header>
            <Table.Row >
              <Table.HeaderCell>Funkcjonalność</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "12px" }}> {packageColor(-1)}<br /><span style={{ fontSize: 13 }}>Pakiet Diamentowy Lite</span></Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "12px" }}> {packageColor(4)}<br /><span style={{ fontSize: 13 }}>Pakiet Diamentowy</span></Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "12px" }}> {packageColor(5)}<br /><span style={{ fontSize: 13 }}>Pakiet Diamentowy Plus</span></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row >
              <Table.Cell>Tworzenie turniejów w domyślnym pakiecie (funkcjonalności patrz tabela powyżej)</Table.Cell>
              <Table.Cell textAlign='center'>
                {packageColor(1)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {packageColor(3)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {packageColor(3)}
              </Table.Cell>
            </Table.Row>

            <Table.Row >
              <Table.Cell>System ligowy - terminarz, kolejki, tabela - (zobacz przykład) <a href='https://turniej-generator.pl/info/system-ligowy' className="ModalInfo" target="_blank" rel="noopener noreferrer">https://turniej-generator.pl/info/system-ligowy</a> </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Limit rozgrywanych lig (jako kategorie np Liga 1 oraz Liga 2 itp) </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(2)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(0)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(0)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Ilość zawodników/drużyn biorących udział w danej kategorii</Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(39)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(0)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(0)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Ilość max uczestników w lidze (suma kategorii)</Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(39 * 2)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(200)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(0)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell style={{ lineHeight: "14px" }}>System ligowy - dodawanie zawodników do drużyny, definiowanie sędziego oraz możliwość dodania protokołu meczowego, a także zaawansowanych statystyk dla zawodników w drużynie </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>



            <Table.Row >
              <Table.Cell style={{ lineHeight: "14px" }}>Dedykowana strona klubu / organizacji / turniejów / ligi, (zobacz przykład):
                {dedicatedWebsites && dedicatedWebsites.length > 0 ?
                  dedicatedWebsites.map((row, i) =>
                    <span key={i} style={{ marginLeft: 5 }}>
                      (<a href={row.url} className="ModalInfo" target="_blank" rel="noopener noreferrer">{row.name}</a>),
                    </span>

                  ) : ""}
                <br />
                oraz dużo innych możliwości.
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>


            <Table.Row >
              <Table.Cell style={{ lineHeight: "12px" }}>Algorytm punktacji turniejowej <br />
                <span style={{ fontSize: 12 }}>Możliwość zdefiniowania własnego algorytmu punktacji turniejowej, bez potrzeby edytowania każdego rekordu tabeli koncowej</span>

              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>

            <Table.Row >
              <Table.Cell style={{ lineHeight: "14px" }}>System ligowy - dodanie zdjęcia drużyny</Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>



            <Table.Row >
              <Table.Cell style={{ lineHeight: "12px" }}>Możliwość dodawania wydarzeń typu zajęcia/treningi z zapisami online<br />

              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell style={{ lineHeight: "12px" }}>Multimedia plików oraz Album Zdjęcia Google dla Ligi <br />
                <span style={{ fontSize: 12 }}>Możliwość wysyłania plików/protokołów z pojedynków w formie pliku oraz możliwość dodania do każdego pojedynku link do albumu Zdjęcia Google</span>

              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>

            <Table.Row >
              <Table.Cell>Możliwość podpięcia własnej domeny lub subdomeny, przekierowanej na Nasz serwer (brak własnego hostingu)</Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>



            <Table.Row>
              <Table.Cell>Widget Ogólny - Ustawienia dedykowanej strony - do osadzenia na własnej stronie www<br />
                <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span>
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Widget treningów/eventów - do osadzenia na własnej stronie www<br />
                <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span></Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Widget turniejów/lig - do osadzenia na własnej stronie www<br />
                <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span></Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Widget cykli - do osadzenia na własnej stronie www<br />
                <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span></Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell style={{ lineHeight: "14px" }}>
                Dostęp do panelu menagera pojedynków (Panel zarządzania pojedynkami dla zawodników/drużyn)<br />
                <span style={{ fontSize: 12 }} className='cursorPointer tllink' onClick={() => authContext.changeModalInfo({ nameModal: "AllowMembersSetScores", name: "Dostęp do panelu menagera pojedynków", value: "" })}>
                  Więcej informacji <Icon name="external" />
                </span>
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(true)}
              </Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell>Panel nominacji i zarządzania pojedynkami dla sędziów<br />
                <span style={{ fontSize: 12 }} className='cursorPointer tllink' onClick={() => window.open("/info/panel-nominacji-zarzadzania-pojedynkami-dla-sedziow", "_blank")}>
                  Więcej informacji <Icon name="external" />
                </span></Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {"+100 PLN netto"}
              </Table.Cell>
            </Table.Row>


            <Table.Row >
              <Table.Cell>System Analizy Protokółów Meczowych FIBA – Koszykówka<br />
                <span style={{ fontSize: 12 }} className='cursorPointer tllink' onClick={() => window.open("/info/analiza-protokołów-FIBA", "_blank")}>
                  Więcej informacji <Icon name="external" />
                </span></Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {retCharTab(false)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {"+150 PLN netto"}
              </Table.Cell>
            </Table.Row>

            <Table.Row >
              <Table.Cell>Priorytet wsparcia technicznego</Table.Cell>
              <Table.Cell textAlign='center'>
                {"Normalny"}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {"Normalny"}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {"Wysoki"}
              </Table.Cell>
            </Table.Row>
          </Table.Body>




          <Table.Footer style={{ backgroundColor: "#e8e8e8", fontWeight: "bold", fontSize: 15, lineHeight: "13px" }}>
            <Table.Row>
              <Table.Cell colSpan={4} textAlign='center'>
                <span style={{ fontSize: 12 }}><u>Bez konieczności zawierania umowy i zbędnych formalności</u>, możesz wykupić, przedłużać subskrypcję w dowolnym momencie jej trwania. Wystarczy kliknąć opcję "PRZEDŁUŻ/SUBSKRYPCJA", aby do okresu obowiązywania subskrypcji dodać kolejne 31 dni. Dodatkowo, masz możliwość zrezygnowania w dowolnym momencie, bez konieczności przedłużania na kolejny miesiąc, wówczas subskrypcja wygaśnie.</span>
              </Table.Cell>

            </Table.Row>
          </Table.Footer>

        </Table>
      </div>

      <Card.Group style={{ marginTop: 50, marginBottom: 70 }} centered>
        {totalValueV2 && totalValueV2.subscription ? totalValueV2.subscription.map((row, i) =>

          <Card key={i} style={{ textAlign: "center", width: 360 }} className={"cardpayd cardpayw"}>
            <Card.Content style={{ lineHeight: "14px" }}>
              <Card.Header style={{ lineHeight: "10px", marginBottom: 20 }}>{packageColor(row.type === -1 ? -1 : row.type === 0 ? 4 : 5)} PAKIET DIAMENTOWY {row.type === -1 ? "LITE" : row.type === 0 ? "" : "PLUS"}</Card.Header>
              <span style={{ fontSize: 14 }}>SUBSKRYPCJA {chcboxMonth ? "ROCZNA" : "MIESIĘCZNA"}  <br /> nie odnawia się automatycznie</span>
              <Grid style={{ marginTop: 10 }}>
                <Grid.Row>
                  <Grid.Column width={6} textAlign='right'>
                    <b>31 DNI</b>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <Checkbox checked={chcboxMonth} onChange={(e, d) => setchcboxMonth(d.checked)} toggle />
                  </Grid.Column>
                  <Grid.Column width={6} textAlign='left'>
                    <b>365 DNI</b>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
            <Card.Content >
              {chcboxMonth ?
                <div style={{ lineHeight: "16px" }}>
                  <span style={{ fontSize: 22, fontWeight: "bold" }}><s style={{ color: "grey" }}>{bruttoToNetto(row.price * 12)} PLN</s> {bruttoToNetto(row.price * 10)} PLN <span style={{ fontSize: 16 }}>netto</span></span><br />
                  <span style={{ fontSize: 13 }}><s style={{ color: "grey" }}>{(row.price * 12).toFixed(2)} PLN</s> {(row.price * 10).toFixed(2)} PLN <span style={{ fontSize: 12 }}>brutto</span></span>
                </div>
                :
                <div style={{ lineHeight: "16px" }}>
                  <span style={{ fontSize: 22, fontWeight: "bold" }}>{bruttoToNetto(row.price)} PLN <span style={{ fontSize: 16 }}>netto</span></span><br />
                  <span style={{ fontSize: 13 }}>{(row.price)} PLN <span style={{ fontSize: 12 }}>brutto</span></span>
                </div>
              }
              {chcboxMonth ? <div style={{ lineHeight: "16px", marginTop: 10, fontSize: 13, fontWeight: "bold" }}>
                12 miesięcy w cenie 10!
              </div> : ""}
              <div style={{ lineHeight: "16px", marginTop: 10, fontSize: 11 }}>
                Do każdej płatności wystawiana jest faktura
              </div>
            </Card.Content>
            <Card.Content extra>
              {authContext.user && authContext.user.subscription_info && authContext.user.subscription_info.subscription_active === true ? <Button color='violet' size='mini' disabled={true}>POSIADASZ AKTYWNĄ SUBSKRYPCJĘ</Button> :
                chcboxMonth ? "ZAPRASZAMY DO KONTAKTU" : <BuyProductCompany payable_service_id={null} buyProductType={2} messageProduct={"Subskrypcja"} isToken={false} isDemo={isDemo} issmall={false} btnname={"WYBIERAM"} price={row.price} />
              }
            </Card.Content>
          </Card>

        ) : ""}


      </Card.Group>


      <PackagesAssist openModalDataPA={openModalDataPA} setopenModalDataPA={setopenModalDataPA} />
    </>
  )
}

export default PackagesGTLCompany;