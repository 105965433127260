import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Container, Button, Form, Message, Grid, Table, Segment, Icon, Input, Tab, Dropdown, Header, Label } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../ConnectWS'
import AuthContext from '../../../context/authContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useReactToPrint } from 'react-to-print';
import useAuth from '../../../hooks/useAuth';
import { blockEditScore, getClassScore, getInfroMatchPlace, getlinkplayerprofile, getStosunekPTK, mobileWSmall } from '../../../myfunctions';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import GetScoreDetails from './GetScoreDetails';
import ShowScoreDetails from './ShowScoreDetails';
import { useTranslation } from 'react-i18next';
import CurrentDuelsPlayingSwiss from './CurrentDuelsPlayingSwiss';





function BracketSWISS(propsRoot) {
    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);

    const [messageStatus, setmessageStatus] = useState({ state: 0, text: "" })
    const { t } = useTranslation();
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars

    const [isloading, setisloading] = useState(false)// eslint-disable-line no-unused-vars
    const [isloadingdrawMatchPairs, setisloadingdrawMatchPairs] = useState(false)


    const [showInfoPlayerData, setshowInfoPlayerData] = useState(null)
    const [showInfoPlayerDataLoading, setshowInfoPlayerDataLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const [openData, setDataOpen] = useState({})
    const [swiss_rounds_count, setswiss_rounds_count] = useState(0)// eslint-disable-line no-unused-vars
    const [panes, setpanes] = useState([]);
    const [isDetailsScore, setisDetailsScore] = useState(false)


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps



        setswiss_rounds_count(propsRoot.remoteJson.swiss_rounds_count)
        setisDetailsScore(checkIsDetailsScore())
        createTabPane(propsRoot.remoteJson && propsRoot.remoteJson.tournament_json ? propsRoot.remoteJson.tournament_json : null)

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.struct_confirmed, propsRoot.remoteJson, propsRoot.subtournaments, propsRoot.subtournament_id, authContext.changeOnlyOneColumn])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps
        document.addEventListener("click", mouseClickHandler)

        return () => { document.removeEventListener("click", mouseClickHandler) }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    function checkIsDetailsScore() {
        let ret = false
        if (propsRoot.remoteJson && propsRoot.remoteJson.final_table && propsRoot.remoteJson.final_table.length > 0) {
            propsRoot.remoteJson.final_table.forEach((line3) => {
                if (line3.total_details_points_winners) {
                    ret = true
                    return true
                }
            })
        }
        return ret;
    }

    const mouseClickHandler = (event) => {

        try {
            if (event.PointerEvent.srcElement.className.include("cursorPointer")) {

            } else {
                setshowInfoPlayerData(null)
            }
        } catch (err) {
            setshowInfoPlayerData(null)
        }

    }

    function drawMatchPairs(round_nr) {

        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz wylosować pary do pojedynków?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => drawMatchPairsACC(round_nr)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function drawMatchPairsACC(round_nr) {

            setisloadingdrawMatchPairs(true)
            ConnectWS("/generator/drawMatchPairsSwiss", authContext.token, { round_nr: round_nr, subtournament_id: propsRoot.subtournament_id }, function (response, logout) {

                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);
                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                }
                setisloadingdrawMatchPairs(false)


            });
        }
    }

    function prevPlaceTable(member_id, round_nr, curr_pos) {
        try {
            let index = -1;
            propsRoot.remoteJson.tournament_json[(round_nr - 2)].result_table.find(function (item, i) { if (item.member_id === member_id) { index = i + 1; return i }; });// eslint-disable-line array-callback-return

            let mpos = index - curr_pos
            if (mpos === 0) {
                return <span className="tabCorner"><span className='tabCornerValue'></span></span>
            }
            return <span className={mpos < 0 ? "tabCornerDown" : "tabCornerUp"}><span className='tabCornerValue'>{(mpos) < 0 ? "" : "+"}{mpos}</span></span>
        } catch (error) {
            return <span className="tabCorner"><span className='tabCornerValue'></span></span>
        }
    }


    //drukowanie
    const componentRefPrint = useRef();
    const handlePrintTab = useReactToPrint({
        content: () => componentRefPrint.current,
    });


    function createTabPane(tournament_json) {
        let panestmp = []

        if (tournament_json === null) {
            return panestmp
        }

        if (tournament_json.length > 0) {
            tournament_json.forEach((row, i) => {
                panestmp.push({
                    menuItem: t("WidokTurnieju.runda") + ' ' + row.round_nr,
                    render: () => <Tab.Pane>
                        <Grid>
                            <Grid.Row columns={width < 1400 ? 1 : authContext.onlyOneColumn ? 1 : 2}  >
                                <Grid.Column>
                                    {propsRoot.onlyView === 1 ? "" :
                                        <Message size='mini'>Uczestnicy w danej rundzie sortowani się po kolumnie PUNKTY NA START RUNDY a następnie PUNKTY NA KONIEC RUNDY, ponieważ na podstawie punktacji na początku rundy dobierane sa pary.</Message>
                                    }
                                    <Segment className='CardBlack' style={{ margin: 3 }}>
                                        <Label style={{ marginBottom: 10 }} color='blue' ribbon>
                                            {t("WidokTurnieju.tabela")} {t("WidokTurnieju.runda")} {row.round_nr}
                                        </Label>

                                        <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                            <Table size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell singleLine  >LP</Table.HeaderCell>
                                                        <Table.HeaderCell singleLine>{t("WidokTurnieju.uczestnik")}</Table.HeaderCell>
                                                        <Table.HeaderCell width={3} style={{ lineHeight: "12px" }}>{t("WidokTurnieju.punkty_na_start")}</Table.HeaderCell>
                                                        <Table.HeaderCell width={3} style={{ lineHeight: "12px" }}>{t("WidokTurnieju.punkty_na_koniec")}</Table.HeaderCell>


                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {row.result_table.map((rowTable, i) => {
                                                        return <Table.Row key={i}>
                                                            <Table.Cell style={{ position: "relative", width: "43px" }} textAlign='right'>{i + 1} {prevPlaceTable(rowTable.member_id, row.round_nr, i + 1)}</Table.Cell>
                                                            <Table.Cell><figure className='imgPlayer'><ShowImgAvatar urlpic={rowTable.member_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, rowTable.member_name, 0, width < mobileWSmall() ? 13 : 14)}</figcaption></figure></Table.Cell>
                                                            <Table.Cell>{rowTable.round_start_points}</Table.Cell>
                                                            <Table.Cell>{rowTable.round_end_points}</Table.Cell>

                                                        </Table.Row>
                                                    })}
                                                </Table.Body>

                                            </Table>

                                        </div>

                                        {/*
                                     <Button style={{ marginTop: 10 }} fluid size='tiny'>
                                        POBIERZ UCZESTNIKÓW
                                    </Button>
                                    */}

                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <div ref={componentRefPrint}>
                                        <Segment className='CardBlack' style={{ margin: 3 }}>
                                            <Label style={{ marginBottom: 10 }} color='blue' ribbon>
                                                {t("WidokTurnieju.tabela_pojedynki")} {t("WidokTurnieju.runda")} {row.round_nr}

                                            </Label>
                                            {propsRoot.onlyView === 1 ? "" :
                                                <Icon className='cursorPointer tllink hiddenPrint' title="DRUKUJ" bordered floated name="print" onClick={handlePrintTab} />
                                            }
                                            {row.matches.length > 0 ?
                                                <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                                    <Table size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                                        <Table.Header>
                                                            <Table.Row textAlign='center'>
                                                                {propsRoot.onlyView === 1 ? "" : <Table.HeaderCell width={1} className='hiddenPrint'></Table.HeaderCell>}
                                                                <Table.HeaderCell width={3}>{t("WidokTurnieju.uczestnik")} A</Table.HeaderCell>
                                                                <Table.HeaderCell singleLine width={1}>{t("WidokTurnieju.wynik")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={3}>{t("WidokTurnieju.uczestnik")} B</Table.HeaderCell>
                                                                {propsRoot.onlyView === 1 ? "" : <Table.HeaderCell width={1} className='hiddenPrint'></Table.HeaderCell>}
                                                                {propsRoot.onlyView === 1 ? "" : <Table.HeaderCell width={2} className='hiddenPrint'>BLOKADA MECZU</Table.HeaderCell>}
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body >
                                                            {row.matches.map((rowMatches, i) => {
                                                                return <Table.Row textAlign='center' key={i}>

                                                                    {propsRoot.onlyView === 1 ? "" : <Table.Cell className='hiddenPrint'><Icon name="info circle" onClick={() => showInfoPlayer(rowMatches.member1_id)} onMouseLeave={() => setshowInfoPlayerData(null)} className='cursorPointer' color='grey' /></Table.Cell>}
                                                                    <Table.Cell singleLine className={getClassScore(rowMatches.member1_score, rowMatches.member2_score, "LS")} ><div style={{ display: "flex", justifyContent: "flex-end" }}><figure className='imgPlayer'><figcaption><span style={{ marginRight: 5 }}>{getlinkplayerprofile(null, rowMatches.member1_name, 0, width < mobileWSmall() ? 13 : 14)}</span></figcaption><ShowImgAvatar urlpic={rowMatches.member1_img_file} mtype={"0"} msize={"mini"} /></figure></div></Table.Cell>
                                                                    <Table.Cell singleLine style={{ lineHeight: "15px", position: "relative" }}>{rowMatches.member1_score === null && rowMatches.member2_score === null ? <>{getInfroMatchPlace(rowMatches.match_place)}vs</> : rowMatches.member1_score + ":" + rowMatches.member2_score} {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' onClick={() => openModal(rowMatches)} name="edit" />}
                                                                        {<ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: rowMatches.member1_img_file, member2_img_file: rowMatches.member2_img_file, member1_name: rowMatches.member1_name, member2_name: rowMatches.member2_name, member1_score: rowMatches.member1_score, member2_score: rowMatches.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: rowMatches.subtournament_match_id, match_details: rowMatches.match_details, match_member1_id: rowMatches.member1_id, match_member2_id: rowMatches.member2_id, reverse: false, checkboxMD: true }} />}
                                                                    </Table.Cell>
                                                                    <Table.Cell singleLine className={getClassScore(rowMatches.member1_score, rowMatches.member2_score, "RS")}><figure className='imgPlayer'><ShowImgAvatar urlpic={rowMatches.member2_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, rowMatches.member2_name, 0, width < mobileWSmall() ? 13 : 14)}</figcaption></figure></Table.Cell>
                                                                    {propsRoot.onlyView === 1 ? "" : <Table.Cell className='hiddenPrint'><Icon name="info circle" onClick={() => showInfoPlayer(rowMatches.member2_id)} onMouseLeave={() => setshowInfoPlayerData(null)} className='cursorPointer' color='grey' /></Table.Cell>}
                                                                    {propsRoot.onlyView === 1 ? "" : <Table.Cell className='hiddenPrint'><Icon className='cursorPointer' onClick={() => lockOrUnLockMatche(rowMatches)} name={rowMatches.blocked === 1 ? "lock" : "unlock"} color={rowMatches.blocked === 1 ? "red" : "grey"} /></Table.Cell>}
                                                                </Table.Row>
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </div>
                                                : <Message size='tiny'>
                                                    <Message.Header>Wylosuj pary!</Message.Header>
                                                    <p>
                                                        Pary meczowe nie zostały jeszcze wylosowane...
                                                    </p>
                                                </Message>}
                                            {propsRoot.onlyView === 1 ? "" : <>
                                                <Button className='hiddenPrint' style={{ marginTop: 10 }} loading={isloadingdrawMatchPairs} disabled={isloadingdrawMatchPairs} fluid size='tiny' onClick={() => drawMatchPairs(row.round_nr)}>
                                                    WYLOSUJ PARY
                                                </Button>
                                                <Message size='tiny' className='hiddenPrint'>
                                                    <Message.Header>Blokada meczu</Message.Header>
                                                    <p>
                                                        Jeżeli pojedynek zostanie zablokowany, wówczas podczas ponownego losowania par, zablokowane pojedynki nie zostaną zmienione!
                                                    </p>
                                                </Message>
                                            </>}
                                        </Segment>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid></Tab.Pane>,
                })
            })
        }


        setpanes(panestmp)
    }

    function lockOrUnLockMatche(rowMatchesData, blocked) {
        let tmp = { ...rowMatchesData }
        if (blocked) {
            if (tmp.blocked === 1) {
                return
            }
            tmp.blocked = 1
        } else {
            tmp.blocked = tmp.blocked === 1 ? 0 : 1
        }

        ConnectWS("/generator/setMatchSwissBlocked ", authContext.token, { ...tmp, tournament_id: propsRoot.tournament_id, subtournament_id: propsRoot.subtournament_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }



        });
    }

    const RoundCount = [
        {
            key: 3,
            text: 3,
            value: 3,
        },
        {
            key: 4,
            text: 4,
            value: 4,
        },
        {
            key: 5,
            text: 5,
            value: 5,
        },
        {
            key: 6,
            text: 6,
            value: 6,
        },
    ]

    function changeCountRound(value) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz zmienić ilość rozgrywanych rund? ',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => changeCountRoundAcc(value)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function changeCountRoundAcc(value) {
            ConnectWS("/generator/editSubtournamentSwissRoundCount ", authContext.token, { subtournament_id: propsRoot.subtournament_id, swiss_rounds_count: value }, function (response, logout) {

                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);
                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                }



            });
        }
    }

    function exitModal() {
        setmessageStatus({ state: 0, text: "" })
        setOpen(false)
        setDataOpen({})
    }

    function openModal(data) {
        setOpen(true)
        setDataOpen(data)
    }

    function modifiDataJson() {
        let nmin = 0
        let nmax = 200
        let removeScore = false;
        if (openData.member1_score === null && openData.member2_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik meczu" })
            return
        }

        if (openData.member1_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla niebieskiego" })
            return
        } else if (openData.member1_score < nmin || openData.member1_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (openData.member2_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla czerwonego" })
            return
        } else if (openData.member2_score < nmin || openData.member2_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (openData.member1_score === "" || openData.member2_score === "") {
            removeScore = true
        }
        let jsonObj = { ...openData, tournament_id: propsRoot.tournament_id, subtournament_id: propsRoot.subtournament_id, removeScore: removeScore }

        setmessageStatus({ state: 0, text: "" })

        ConnectWS("/generator/setMatchScoreSwiss", authContext.token, jsonObj, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {

                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                lockOrUnLockMatche(openData, 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }

    function delScore() {
        setmessageStatus({ state: 0, text: "" })
        ConnectWS("/generator/setMatchScoreSwiss", authContext.token, { ...openData, subtournament_id: propsRoot.subtournament_id, removeScore: true }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                lockOrUnLockMatche(openData, 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }


    function showInfoPlayer(member_id) {

        setshowInfoPlayerDataLoading(true)
        ConnectWS("/generator/getMemberMatchesSwiss", authContext.token, { member_id: member_id, tournament_id: propsRoot.tournament_id, subtournament_id: propsRoot.subtournament_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {


                //addToast(response.data.message, { appearance: 'success' });
                //authContext.changeRefreshView(authContext.refreshView + 1)
                let arrtmp = [];
                arrtmp.length = 0;
                if (response.data.length > 0) {
                    response.data.forEach((data, i) => {
                        arrtmp.push((data.member1_id + data.member2_id))
                    })
                }

                let resultToReturn = false;
                resultToReturn = arrtmp.some((element, index) => {
                    return arrtmp.indexOf(element) !== index
                });
                if (resultToReturn) {
                    addToast("Pojawił się podwójny mecz! dla tego zawodnika", { appearance: 'warning' });
                }
                else {

                }
                setshowInfoPlayerData(response.data)
            } else {

            }
            setshowInfoPlayerDataLoading(false)


        });
    }


    return (
        <>
            {propsRoot.subT_type && propsRoot.onlyView === 0 ?
                <Message className='hiddenPrint' color='brown' size='mini'
                    icon='winner'
                    header={propsRoot.subT_type}
                    content='Rodzaj rozgrywanego turnieju'
                /> : ""}
            {propsRoot.onlyView === 1 ? '' : <CurrentDuelsPlayingSwiss tournamentJsonRoot={propsRoot.tournamentJsonRoot} subtournament_id={propsRoot.subtournament_id} tournamentGroups={propsRoot.remoteJson.tournament_json} openModal={openModal} />}
            {propsRoot.onlyView === 1 ? "" :
                <Header as='h5' style={{ margin: 0, padding: 0, marginLeft: 20, marginBottom: 10 }}>
                    <Header.Content style={{ margin: 0, padding: 0 }}>
                        SYSTEM SZWAJCARSKI <span className='hiddenPrint'>- Ilość rozgrywanych rund w turnieju to {' '}</span>
                        <Dropdown
                            inline
                            header='Ilość rund'
                            options={RoundCount}
                            value={swiss_rounds_count}
                            onChange={(e, d) => changeCountRound(d.value)}
                        />
                    </Header.Content>

                </Header>
            }

            {showInfoPlayerData !== null && showInfoPlayerData.length > 0 ?
                <Segment color='blue' className='SwissPlayerInfo' loading={showInfoPlayerDataLoading} >
                    <Label color='blue' ribbon>
                        Rozegrane mecze w turnieju:
                    </Label>

                    <div style={{ overflowX: "auto", width: width < 700 ? (width - 110) : "500px" }} >
                        <Table size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                            <Table.Header>
                                <Table.Row textAlign='center'>
                                    <Table.HeaderCell >RUNDA</Table.HeaderCell>
                                    <Table.HeaderCell >UCZESTNIK A</Table.HeaderCell>
                                    <Table.HeaderCell singleLine width={1}>WYNIK</Table.HeaderCell>
                                    <Table.HeaderCell >UCZESTNIK B</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {showInfoPlayerData.length > 0 ? showInfoPlayerData.map((rowMatches, i) => {
                                    return <Table.Row textAlign='center' key={i}>
                                        <Table.Cell>{rowMatches.round_nr}</Table.Cell> <Table.Cell inverted={propsRoot.isDarkTheme}
                                            className={getClassScore(rowMatches.member1_score, rowMatches.member2_score, "LS")}
                                        >{rowMatches.member1_name}</Table.Cell>
                                        <Table.Cell >{rowMatches.member1_score === null && rowMatches.member2_score === null ? "vs" : rowMatches.member1_score + ":" + rowMatches.member2_score}
                                        </Table.Cell>
                                        <Table.Cell
                                            className={getClassScore(rowMatches.member1_score, rowMatches.member2_score, "RS")}
                                        >{rowMatches.member2_name}</Table.Cell>
                                    </Table.Row>
                                }) : ""}

                            </Table.Body>

                        </Table>

                    </div>
                </Segment>
                : ""}

            <Tab menu={{ secondary: true, pointing: true, color: "blue", className: "wrapped" }} panes={panes} />


            {propsRoot.remoteJson === null || propsRoot.remoteJson.subtournament_finished ? "" :
                <Segment className='CardBlack'  >
                    <Label color={'blue'} ribbon>
                        {t("WidokTurnieju.tabela")}
                    </Label>

                    <div style={{ overflowX: "auto", width: "100%", marginTop: 5 }} >

                        <Table className='stickyf2c' size='small' singleLine striped compact unstackable inverted={propsRoot.isDarkTheme}>
                            <Table.Header>
                                <Table.Row>


                                    <Table.HeaderCell singleLine width={1} textAlign='center' title="Miejsce">{width > 1000 ? t("WidokTurnieju.tabela_miejsce") : t("WidokTurnieju.tabela_miejsce_mala")}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' >{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine title="Punktacja">{width > 1000 ? t("WidokTurnieju.tabela_ptk") : "PKT"}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine title="Mecze">{width > 1000 ? t("WidokTurnieju.tabela_pojedynki") : "LP"}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine title="Wygrana">{width > 1000 ? t("WidokTurnieju.tabela_wygrane") : "W"}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine title="Remis">{width > 1000 ? t("WidokTurnieju.tabela_remis") : "R"}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine title="Przegrane">{width > 1000 ? t("WidokTurnieju.tabela_przegrane") : "P"}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine title="Bilans">{width > 1000 ? t("WidokTurnieju.tabela_bilans") : "B"}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center' singleLine>{t("WidokTurnieju.tabela_stosunek")}</Table.HeaderCell>
                                    {isDetailsScore ? <>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{width > 1000 ? t("WidokTurnieju.tabela_bilans") : "B"}
                                            <br /><span style={{ fontSize: 9 }}>{width > 1000 ? t("WidokTurnieju.tabela_bilans_szczegoly") : "SZCZEG. PKT"}</span></Table.HeaderCell>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{t("WidokTurnieju.tabela_stosunek")}
                                            <br /><span style={{ fontSize: 9 }}>{width > 1000 ? t("WidokTurnieju.tabela_bilans_szczegoly") : "SZCZEG. PKT"}</span></Table.HeaderCell>
                                    </> : ""}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                {propsRoot.remoteJson && propsRoot.remoteJson.final_table && propsRoot.remoteJson.final_table.length > 0 ?
                                    propsRoot.remoteJson.final_table.map((line3, i) =>
                                        <Table.Row key={i}  >

                                            <Table.Cell textAlign='center' singleLine>{line3.order_nr_range}</Table.Cell>
                                            <Table.Cell textAlign='center' ><figure className='imgPlayer'><ShowImgAvatar urlpic={line3.img_file} mtype={propsRoot && propsRoot.remoteJsonFull ? propsRoot.remoteJsonFull.member_type : "0"} /><figcaption>{getlinkplayerprofile(line3.member_id, line3.member_name, 1, width < mobileWSmall() ? 13 : 16, "_blank")}</figcaption></figure></Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.subtournament_point} </Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.matches_played}</Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.wins}</Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.draws}</Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.loses}</Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>{line3.total_points_winners} : {line3.total_points_losers}</Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_points_winners, line3.total_points_losers)}</Table.Cell>
                                            {isDetailsScore ? <>
                                                <Table.Cell textAlign='center' singleLine>{line3.total_details_points_winners ? line3.total_details_points_winners : 0}:{line3.total_details_points_losers ? line3.total_details_points_losers : 0}</Table.Cell>
                                                <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_details_points_winners ? line3.total_details_points_winners : 0, line3.total_details_points_losers ? line3.total_details_points_losers : 0)}</Table.Cell>
                                            </> : ""}
                                        </Table.Row>)
                                    : ""}

                            </Table.Body>

                        </Table>

                    </div>
                </Segment>

            }
            <Modal
                // onClose={() => exitModal()}
                onOpen={() => setOpen(true)}
                open={open}
                centered={width < 900 ? false : true}
            //closeIcon


            >
                <Modal.Header>Wprowadź wynik</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }} >
                    <Modal.Description >
                        <Container textAlign='center'>

                            <Segment >
                                <Form size='small' >
                                    <Form.Field inline>
                                        <Grid verticalAlign='middle' fluid stackable columns={3}>
                                            <Grid.Row>
                                                <Grid.Column width={7} textAlign='center'><b><label style={{ fontSize: 16, color: "#292929" }}>{openData ? openData.member1_name : '?'}</label></b><br />
                                                    <Input disabled={blockEditScore(openData ? openData.member1_score : 0, openData ? openData.member2_score : 0, propsRoot.systemId, openData ? openData.match_details : null) || isloading} autoFocus fluid type="number" value={openData.member1_score >= 0 ? openData.member1_score : null} onChange={(e, d) => setDataOpen({ ...openData, member1_score: (d.value), match_details: propsRoot.systemId === 5 ? null : openData.match_details })} style={{ padding: 5, margin: 5, border: propsRoot.systemId===14 ? "2px solid #ffc9c9" : "2px solid rgb(224, 224, 224)", borderRadius: 5 }} />
                                                </Grid.Column>
                                                <Grid.Column textAlign='center' width={2}>
                                                    <label style={{ fontSize: 25, fontWeight: "bold", color: "#737373" }}>VS</label>
                                                </Grid.Column>
                                                <Grid.Column width={7} textAlign='center'>
                                                    <b><label style={{ fontSize: 16, color: "#292929" }} >{openData ? openData.member2_name : '?'}</label></b><br />
                                                    <Input disabled={blockEditScore(openData ? openData.member1_score : 0, openData ? openData.member2_score : 0, propsRoot.systemId, openData ? openData.match_details : null) || isloading} fluid type="number" value={openData.member2_score >= 0 ? openData.member2_score : null} onChange={(e, d) => setDataOpen({ ...openData, member2_score: (d.value), match_details: propsRoot.systemId === 5 ? null : openData.match_details })} style={{ padding: 5, margin: 5, border: "2px solid rgb(224, 224, 224)", borderRadius: 5 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                    </Form.Field>

                                </Form>
                                <Button size="tiny"
                                    content="ZAPISZ WYNIK"
                                    labelPosition='right'
                                    icon='checkmark'
                                    onClick={() => modifiDataJson()}
                                    color='green'
                                    loading={isloading}
                                    fluid
                                    disabled={isloading}
                                />
                            </Segment>
                            {openData ?
                                <GetScoreDetails subtournamentJsonRoot={propsRoot.remoteJsonFull} tournamentJsonRoot={propsRoot.tournamentJsonRoot} tournament_free={propsRoot.tournament_free} systemId={propsRoot.systemId} openModalScoreTMP={{ subtournament_match_id: openData.subtournament_match_id, member1_name: openData.member1_name, member2_name: openData.member2_name, member1_score: openData.member1_score, member2_score: openData.member2_score, match_details: openData.match_details, match_place: openData.match_place, match_member1_id: openData.member1_id, match_member2_id: openData.member2_id }} openModalScore={openData} setopenModalScore={setDataOpen} />
                                : ""}
                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>
                    <Button floated='left' size="tiny" color='grey' labelPosition='right' icon='trash' onClick={() => delScore()} content="KASUJ WYNIK" />


                    <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />

                </Modal.Actions>
            </Modal>

            <br />
        </>
    )
}

export default BracketSWISS;