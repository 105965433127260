import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Button, Checkbox, Grid, Message, Label, List, Input, Container, Form, Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { changeInitialScale, checkDateOnlyStartBeforeNow, defJsonCFGConfigurator, getlinkcycleWidget, loadingShow, normalizeStringURL } from '../../../../myfunctions';
import { ColorPicker } from 'primereact/colorpicker';
import { CodeBlock } from 'react-code-blocks';
import CallBackTG from '../../../../tools/CallBackTG';
import { TabPanel, TabView } from 'primereact/tabview';
import MyImageLazy from '../../../../tools/MyImageLazy';
import AddImgMediaUser from '../../../../tools/AddImgMediaUser';
import GoogleSuggest from '../../../../tools/google/GoogleSuggest';



function WebsiteConfigurator(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false);// eslint-disable-line no-unused-vars
  const [loading1, setloading1] = useState(true);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);
  const isDemo = useCheckUserIsDemo()// eslint-disable-line no-unused-vars

  const [jsonCFG, setjsonCFG] = useState(defJsonCFGConfigurator());
  const [xApiKey, setxApiKey] = useState(null);

  const [pagename, setpagename] = useState("");
  const [checkboxTournamentList, setcheckboxTournamentList] = useState(true);
  const [activeIndexA, setactiveIndexA] = useState(0);

  const [tournamenyid, settournamenyid] = useState(0);
  const [subtournamenyid, setsubtournamenyid] = useState(0);
  const [allCategory, setallCategory] = useState(0);

  const [Tournament, setTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [TournamentAll, setTournamentAll] = useState([]);// eslint-disable-line no-unused-vars
  const [SubTournament, setSubTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [imgUrlTG, setimgUrlTG] = useState(null);
  const [imgUrlTGBackground, setimgUrlTGBackground] = useState(null);



  const [tournamentCycle, setTournamentCycle] = useState(null);
  const [tournamentCycleRow, setTournamentCycleRow] = useState(null);
  const [tournamentCycleList, settournamentCycleList] = useState([]);




  useEffect(() => {

    setloading1(true)
    changeInitialScale(0.5)
    getOrganizerWebsiteCfg()
    getTournaments()
    getAllCycles()
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: ChangePassword")
    }
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setjsonCFG({ ...jsonCFG, site_logo: imgUrlTG })
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [imgUrlTG])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setjsonCFG({ ...jsonCFG, bgImage: imgUrlTGBackground })
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [imgUrlTGBackground])// eslint-disable-line react-hooks/exhaustive-deps




  function getOrganizerWebsiteCfg() {
    setloading(true)
    ConnectWS("dedicated/getOrganizerWebsiteCfg", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data) {
          try {
            if (response.data && response.data.site_cfg.bgcolor) {
              setjsonCFG({ ...jsonCFG, ...response.data.site_cfg })
              if (response.data.site_cfg.site_logo) {
                setimgUrlTG(response.data.site_cfg.site_logo)
              }
              if (response.data.site_cfg.bgImage) {
                setimgUrlTGBackground(response.data.site_cfg.bgImage)
              }
              

            } else {
              setjsonCFG(defJsonCFGConfigurator())
            }

          } catch (e) {

          }
          try {
            setxApiKey(response.data.x_api_key)
          } catch (e) {

          }
          try {
            setpagename(response.data.website_name)
          } catch (e) {

          }
        }


      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading1(false)
      setloading(false)
    });
  }


  function addOrganizerWebsite() {
    ConnectWS("dedicated/addOrganizerWebsite", authContext.token, { website_name: pagename }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getOrganizerWebsiteCfg()
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
    });
  }



  function setOrganizerWebsiteCfg() {
    setloading(true)
    ConnectWS("dedicated/setOrganizerWebsiteCfg", authContext.token, { site_cfg: jsonCFG }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        document.getElementById('siteTGif').src += '';
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)
    });
  }

  function getInfoUrl(pagename) {
    return <>
      Dedykowana strona możesz przekazać dowolny z poniższych linków do swojej strony zainteresowanym osobom. <br /> <br />
      <div><b>Propozycja 1:</b> <a className={"tllink"} href={"/klub/" + normalizeStringURL(pagename)} target="_blank" rel="noopener noreferrer">turniej-generator.pl/klub/{normalizeStringURL(pagename)}</a></div>
      <div><b>Propozycja 2:</b> <a className={"tllink"} href={"/turnieje/" + normalizeStringURL(pagename)} target="_blank" rel="noopener noreferrer">turniej-generator.pl/turnieje/{normalizeStringURL(pagename)}</a></div>
      <div><b>Propozycja 3:</b> <a className={"tllink"} href={"/liga/" + normalizeStringURL(pagename)} target="_blank" rel="noopener noreferrer">turniej-generator.pl/liga/{normalizeStringURL(pagename)}</a></div>
      <div style={{ marginTop: 10 }}><b>WYBRANY WIDOK:</b> {jsonCFG.view_widget ? jsonCFG.view_widget.name : "-"}</div>
    </>
  }



  function stepCreateIframe(iframeData) {
    return <>
      <Divider />
      <Header as='h4'>Krok 1: Otwórz kod HTML swojej strony</Header>
      <p>
        Upewnij się, że masz dostęp do pliku HTML swojej strony. Możesz go edytować za pomocą edytora tekstu (np. Notepad++, Visual Studio Code)
        lub za pomocą narzędzi dostępnych w CMS (Content Management System) jeśli używasz takiego systemu (np. WordPress).
      </p>
      <Header as='h4'>Krok 2: Wybierz miejsce, w którym chcesz osadzić stronę</Header>
      <p>
        Zlokalizuj miejsce w kodzie HTML, w którym chcesz osadzić inną stronę.
        Może to być sekcja treści strony lub dowolne inne miejsce, gdzie chcesz, aby iframe się pojawiło.
      </p>

      <Header as='h4'>Krok 3: Dodaj element iframe</Header>
      <p>
        Wprowadź następujący kod HTML w wybranym miejscu:
      </p>
      <Message >
        <CodeBlock
          text={'<iframe id="siteTGif" src="' + iframeData + '" width="100%" frameborder="0" height="800px" title="turniej-generator.pl" style="border:none;"></iframe>'}
          language='html'
          showLineNumbers={false}

        />
      </Message>
      <p>
        <List bulleted>
          <List.Item>width: Ustaw szerokość iframe w pikselach lub jako procent szerokości rodzica, np. width="100%".</List.Item>
          <List.Item>height: Ustaw wysokość na sztywno iframe w pikselach, np. height="800".</List.Item>
          <List.Item>frameborder: Ustaw frameborder="0" aby usunąć ramkę wokół iframe, lub ustaw na 1, aby ją pokazać.</List.Item>



        </List>
      </p>
      <p>
        Zapisz zmiany w pliku HTML i prześlij plik na serwer, jeśli pracujesz lokalnie.
        Jeśli korzystasz z CMS, zmiany zostaną zapisane automatycznie po publikacji.
      </p>
      <Divider />

      <Accordion fluid styled>
        <AccordionTitle
          active={activeIndexA === 0}
          index={0}
          onClick={() => setactiveIndexA(activeIndexA === 1 ? 0 : 1)}
        >
          <Icon name='dropdown' />
          <Label color='blue'>Posiadasz stronę postawioną na WordPress? - Zainstaluj naszą wtyczkę</Label>
        </AccordionTitle>
        <AccordionContent active={activeIndexA === 0}>

          <Header as='h4'>Krok 3.1: Pobranie wtyczki</Header>
          <div>
            <Button as='div' labelPosition='right' onClick={() => window.open('https://turniej-generator.pl/widget/widget-site-turniejgenerator.zip', '_self')}>
              <Button>
                <Icon name='cloud download' />
                POBIERZ WTYCZKĘ
              </Button>
              <Label color='grey' pointing='left'>
                VER: 1.0
              </Label>
            </Button>

          </div>


          <Header as='h4'>Krok 3.2: Zaloguj się do panelu administracyjnego WordPressa</Header>
          <p>
            <List bulleted>
              <List.Item>Wejdź na swoją stronę WordPressa i zaloguj się do panelu administracyjnego</List.Item>
              <List.Item>W menu po lewej stronie kliknij "Wtyczki".</List.Item>
              <List.Item>Kliknij przycisk <b>Dodaj wtyczkę</b>, następni kliknij <b>Wyślij wtyczkę na serwer</b>, wybierz pobraną wtyczkę <i>widget-site-turniejgenerator.zip</i> i kliknij <b>Zainstaluj</b>.</List.Item>


            </List>

          </p>
          <Header as='h4'>Krok 3.3: Znajdź wtyczkę "Widget Strony Turniej Generator" na liście:</Header>
          <p>
            <List bulleted>
              <List.Item>Wyszukaj wtyczkę "Widget Strony Turniej Generator" na liście zainstalowanych wtyczek.</List.Item>
              <List.Item>Kliknij <b>Aktywuj</b>, aby włączyć wtyczkę.</List.Item>
            </List>

          </p>
          <Header as='h4'>Krok 3.4: Użycie wtyczki</Header>
          <p>
            <List bulleted>
              <List.Item>Przejdź do sekcji "Strony" lub "Wpisy" i utwórz nową stronę albo edytuj istniejącą.</List.Item>
              <List.Item>W edytorze treści dodaj <b>shortcode:</b></List.Item>
              <Message >
                <CodeBlock

                  text={'[widget_site_tg url="' + iframeData + '" width="100%" height="900px" dynamic_height="false"]'}
                  language='html'
                  showLineNumbers={false}
                />
              </Message>
              <p>
                <List bulleted>
                  <List.Item>width: Ustaw szerokość iframe w pikselach lub jako procent szerokości rodzica, np. width="100%".</List.Item>
                  <List.Item>height: Ustaw wysokość na sztywno iframe w pikselach, np. height="900px".</List.Item>
                  <List.Item>dynamic_height: Ustaw na true jeżeli wysokość ma być ładowana dynamicznie lub ustaw jako false, wówczas wysokość będzie pobrane z parametru height</List.Item>



                </List>
              </p>
              <List.Item>Zapisz zmiany i opublikuj stronę, aby zobaczyć osadzony iframe.</List.Item>
            </List>
          </p>


        </AccordionContent>
      </Accordion>



      <Header as='h4'>Krok 4: Sprawdź stronę</Header>
      <p>
        Odśwież swoją stronę w przeglądarce internetowej, aby upewnić się, że iframe wyświetla osadzoną stronę poprawnie.
      </p>
    </>
  }

  function getTournaments() {

    ConnectWS("/generator/getTournaments", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        let dictemat2 = []
        dictemat2.length = 0
        response.data.forEach((data, i) => {
          if (checkDateOnlyStartBeforeNow(data.start_date)) {
            dictemat.push({ key: i, text: data.name + " - " + data.start_date, value: data.tournament_id })
          }
          dictemat2.push({ key: i, text: data.name + " - " + data.start_date, value: data.tournament_id })
        })
        //setRowDataTournament(response.data)
        setTournament(dictemat);
        setTournamentAll(dictemat2)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function getAllCycles() {

    ConnectWS("/cycle/getCycles", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name, value: data })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })

        setTournamentCycle(dictemat);

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function getCycleSubtournaments(cycle_id) {

    ConnectWS("/cycle/getCycleSubtournaments", authContext.token, { cycle_id: cycle_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {


        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name, value: data })
        })

        settournamentCycleList(dictemat);

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function ChangeCycleBox(data, co) {
    console.log(data)
    if (data.group_subtournaments === 0 && co === 1) {
      getCycleSubtournaments(data.cycle_id)
    }
    if (co === 1) {
      setTournamentCycleRow({ cycleId: data.cycle_id, cycleName: data.name, group_subtournaments: data.group_subtournaments, subtournamentName: data.group_subtournaments === 1 ? "all" : null })
    } else {
      setTournamentCycleRow({ ...tournamentCycleRow, subtournamentName: data.name })

    }
  }


  function ChangeTournamentBox(data) {
    setallCategory(0)
    settournamenyid(data.value)
    ConnectWS("/generator/getSubtournaments", authContext.token, { tournament_id: data.value }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name, value: data.subtournament_id })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })
        setSubTournament(dictemat);


      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function clearSelectTST() {
    setallCategory(0)
    settournamenyid(0)
    setsubtournamenyid(0);
    setTournamentCycleRow(null)
  }

  return (
    <> <Header as='h5' textAlign='center' block>
      <Icon name='globe' />
      <Header.Content>Konfigurator strony gotowej do osadzenia na własnej stronie WWW</Header.Content>
    </Header>

      {loading1 ? loadingShow() : <>
        {xApiKey ? <>
          <Container textAlign="center">


            {getInfoUrl(pagename)}

          </Container>
          <Header as='h5' textAlign='center' block>
            <Icon name='globe' />
            <Header.Content>Instrukcja jak osadzić widget na własnej stronie WWW</Header.Content>
          </Header>

          <TabView activeIndex={activeIndex} scrollable onTabChange={(e) => {
            setActiveIndex(e.index)
          }}>
            <TabPanel header={"WIDGET OGÓLNNY"} style={{ padding: 0, margin: 0 }} >

              {authContext && authContext.user && authContext.user.subscription_info && ((authContext.user.subscription_info.subscription_active === true && authContext.user.subscription_info.subscription_extended >= 0) || authContext.user.subscription_info.ignore_active_subscription === 1) ?
                <>

                  <Grid stackable style={{ marginTop: 10 }}>
                    <Grid.Row >
                      <Grid.Column width={6}>
                        <div style={{ marginTop: 10, marginBottom: 20 }}><Checkbox toggle disabled={jsonCFG.view_widget ? jsonCFG.view_widget.id === 1 ? true : false : false} label="Wybierz ten widget do strony dedykowanej" onChange={(e, d) => setjsonCFG({ ...jsonCFG, view_widget: { id: 1, name: "WIDGET OGÓLNY", value: "" } })} checked={jsonCFG.view_widget ? jsonCFG.view_widget.id === 1 ? true : false : false} /> </div>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>PARAMETRY DEDYKOWANE:</div>
                        <div ><ColorPicker format="hex" value={jsonCFG.bgcolor} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.bgcolor} onChange={(e, d) => setjsonCFG({ ...jsonCFG, bgcolor: d.value })} /> Kolor tła</div>

                        <div style={{ marginTop: 10, marginBottom: 20 }}><Checkbox toggle label="Ciemny motyw strony" onChange={(e, d) => setjsonCFG({ ...jsonCFG, dark: d.checked })} checked={jsonCFG.dark ? true : false} /> </div>



                        <Form size='mini'>
                          <Form.Group widths={'equal'}>
                            {imgUrlTG && imgUrlTG !== "" ? <MyImageLazy openView={true} maxHeight={100} src={imgUrlTG} /> : ""}
                            <Form.Field
                              control={Input}
                              value={imgUrlTG}
                              type='url'
                              onChange={(event, data) => setimgUrlTG(data.value)}
                              label='Link URL do loga'
                              placeholder='Link URL do loga'
                            />

                            <AddImgMediaUser imgUrlTG={imgUrlTG} setimgUrlTG={setimgUrlTG} />

                          </Form.Group>
                          <Form.Field
                            control={Input}
                            value={jsonCFG.site_logo_width}
                            type='number'

                            onChange={(e, d) => setjsonCFG({ ...jsonCFG, site_logo_width: d.value !== "" ? Number(d.value) : d.value })}
                            label='Szerokość logo w px'
                            placeholder='Szerokość logo w px'
                          />
                          <Form.Field
                            control={Input}
                            value={jsonCFG.site_name1}
                            onChange={(e, d) => setjsonCFG({ ...jsonCFG, site_name1: d.value })}
                            label='Nazwa strony1'
                            placeholder='Nazwa strony1'
                          />
                          <Form.Field
                            control={Input}
                            value={jsonCFG.site_name2}
                            onChange={(e, d) => setjsonCFG({ ...jsonCFG, site_name2: d.value })}
                            label='Nazwa strony2'
                            placeholder='Nazwa strony2'
                          />
                          {jsonCFG.InputValuePlace !== null ?
                            <Form.Field>
                              <label>Lokalizacja rozgrywek</label>
                              {jsonCFG.InputValuePlace.address}
                              <Button onClick={(e, d) => setjsonCFG({ ...jsonCFG, InputValuePlace: null })} fluid size='mini'>Zmień na inną</Button>
                            </Form.Field>
                            :
                            <GoogleSuggest form={jsonCFG} setForm={setjsonCFG} />
                          }
                        </Form>


                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Informacja o stronie" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_site_info: d.checked ? 1 : 0 })} checked={jsonCFG.show_site_info ? true : false} /> </div>

                        <div style={{ marginTop: 10 }}>
                          <ColorPicker format="hex" value={jsonCFG.site_info_bgcolor} onChange={(e) => setjsonCFG({ ...jsonCFG, site_info_bgcolor: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.site_info_bgcolor} onChange={(e, d) => setjsonCFG({ ...jsonCFG, site_info_bgcolor: d.value })} /> Kolor tła informacji o stronie
                        </div>

                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Statystyki" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_statistics: d.checked ? 1 : 0 })} checked={jsonCFG.show_statistics ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}>
                          <ColorPicker format="hex" value={jsonCFG.bgcolor_stats} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor_stats: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.bgcolor_stats} onChange={(e, d) => setjsonCFG({ ...jsonCFG, bgcolor_stats: d.value })} /> Kolor tła statystyk
                          <ColorPicker style={{ marginLeft: 5 }} format="hex" value={jsonCFG.label_color_stats} onChange={(e) => setjsonCFG({ ...jsonCFG, label_color_stats: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.label_color_stats} onChange={(e, d) => setjsonCFG({ ...jsonCFG, label_color_stats: d.value })} /> Kolor wstążki statystyk
                        </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Informacja o nowych turniejach/ligach" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_new_tournament: d.checked ? 1 : 0 })} checked={jsonCFG.show_new_tournament ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Informacja o ostatnim cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_last_cycle: d.checked ? 1 : 0 })} checked={jsonCFG.show_last_cycle ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Lista turniejów/lig" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_all_tournaments: d.checked ? 1 : 0 })} checked={jsonCFG.show_all_tournaments ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Lista cykli" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_all_cycles: d.checked ? 1 : 0 })} checked={jsonCFG.show_all_cycles ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="TOP10 ELO" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_top10_member_elo: d.checked ? 1 : 0 })} checked={jsonCFG.show_top10_member_elo ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="TOP10 Uczestników z pierwszym miejscem" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_top10_member_winners: d.checked ? 1 : 0 })} checked={jsonCFG.show_top10_member_winners ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="TOP10 Uczestników na podium" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_top10_member_podium: d.checked ? 1 : 0 })} checked={jsonCFG.show_top10_member_podium ? true : false} /> </div>
                        <Divider />
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy - Wyłączone" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_members: d.checked ? 0 : 1 })} checked={jsonCFG.show_members === 0 ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy - karta osiągnięć" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_members: d.checked ? 1 : 2 })} checked={jsonCFG.show_members === 1 ? true : false} /> </div>
                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy - tabela" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_members: d.checked ? 2 : 1 })} checked={jsonCFG.show_members === 2 ? true : false} /> </div>


                        <Button fluid style={{ marginTop: 10 }} onClick={() => setOrganizerWebsiteCfg()} loading={loading} color='green' disabled={loading}>ZAPISZ USTAWIENIA</Button>



                      </Grid.Column>
                      {xApiKey ?
                        <Grid.Column width={10} >
                          <Label color='grey' size='mini' style={{ marginBottom: 5 }}>
                            Podgląd: <a href={"/widget/site/" + xApiKey} target="_blank" rel="noopener noreferrer">ZOBACZ STRONĘ W NOWYM OKNIE</a>
                          </Label>
                          <iframe id="siteTGif" src={"/widget/site/" + xApiKey} width="100%" height={900} title="Turniej-Generator" frameborder="1" ></iframe>

                        </Grid.Column>
                        : ""}
                    </Grid.Row>


                  </Grid>

                  {stepCreateIframe("https://turniej-generator.pl/widget/site/" + xApiKey)}
                </> : <Message style={{ marginTop: 20 }}
                  error
                  icon='info circle'
                  header='Brak aktywnej subskrypcji'
                  content='Moduł dostępny jest w Pakiecie Diamentowym lub Pakiecie Diamentowym Plus'
                />

              }
            </TabPanel>

            <TabPanel header={"WIDGET TRENINGÓW/EVENTÓW"} style={{ padding: 0, margin: 0 }} >

              {authContext && authContext.user && authContext.user.subscription_info && ((authContext.user.subscription_info.subscription_active === true && (authContext.user.subscription_info.subscription_extended === -1 || authContext.user.subscription_info.subscription_extended === 1)) || authContext.user.subscription_info.ignore_active_subscription === 1) ?
                <>
                  <Grid stackable style={{ marginTop: 10 }}>
                    <Grid.Row >
                      <Grid.Column width={6}>
                        <div style={{ marginTop: 10, marginBottom: 20 }}><Checkbox toggle disabled={jsonCFG.view_widget ? jsonCFG.view_widget.id === 2 ? true : false : false} label="Wybierz ten widget do strony dedykowanej" onChange={(e, d) => setjsonCFG({ ...jsonCFG, view_widget: { id: 2, name: "WIDGET TRENINGÓW/EVENTÓW", value: { xApiKey: xApiKey } } })} checked={jsonCFG.view_widget ? jsonCFG.view_widget.id === 2 ? true : false : false} /> </div>

                        <div style={{ marginTop: 10, marginBottom: 20 }}>PARAMETRY DEDYKOWANE:</div>
                        <div ><ColorPicker format="hex" value={jsonCFG.bgcolor_activity} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor_activity: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.bgcolor_activity} onChange={(e, d) => setjsonCFG({ ...jsonCFG, bgcolor_activity: d.value })} /> Kolor tła</div>


                        <div style={{ marginTop: 10 }}><Checkbox toggle label="Ciemny motyw strony" onChange={(e, d) => setjsonCFG({ ...jsonCFG, dark_activity: d.checked })} checked={jsonCFG.dark_activity ? true : false} /> </div>


                        <Button fluid style={{ marginTop: 10 }} onClick={() => setOrganizerWebsiteCfg()} color='green' loading={loading} disabled={loading}>ZAPISZ USTAWIENIA</Button>



                      </Grid.Column>
                      {xApiKey ?
                        <Grid.Column width={10} >

                          <Label color='grey' size='mini' style={{ marginBottom: 5 }}>
                            Podgląd: <a href={"/widget/activity/" + xApiKey} target="_blank" rel="noopener noreferrer">ZOBACZ STRONĘ W NOWYM OKNIE</a>
                          </Label>
                          <iframe id="siteTGif" src={"/widget/activity/" + xApiKey} width="100%" height={500} title="Turniej-Generator treningi" frameborder="1" ></iframe>
                        </Grid.Column>
                        : ""}
                    </Grid.Row>


                  </Grid>

                  {stepCreateIframe("https://turniej-generator.pl/widget/activity/" + xApiKey)}

                </> : <Message style={{ marginTop: 20 }}
                  error
                  icon='info circle'
                  header='Brak aktywnej subskrypcji'
                  content='Moduł dostępny jest w Pakiecie Diamentowym Lite lub Pakiecie Diamentowym Plus'
                />}
            </TabPanel>

            <TabPanel header={"WIDGET TURNIEJ/LIGA"} style={{ padding: 0, margin: 0 }} >
              {authContext && authContext.user && authContext.user.subscription_info && ((authContext.user.subscription_info.subscription_active === true && (authContext.user.subscription_info.subscription_extended >= 0)) || authContext.user.subscription_info.ignore_active_subscription === 1) ?
                <>
                  {tournamenyid !== 0 && (subtournamenyid !== 0 || allCategory === 1) ? <>
                    <Button fluid onClick={() => clearSelectTST()}>WYBIERZ INNY TURNIEJ/LIGĘ</Button>
                    <Grid stackable style={{ marginTop: 10 }}>
                      <Grid.Row >
                        <Grid.Column width={6}>
                          <div style={{ marginTop: 10, marginBottom: 20 }}><Checkbox toggle label="Wybierz ten widget do strony dedykowanej" onChange={(e, d) => setjsonCFG({ ...jsonCFG, view_widget: { id: 3, name: "WIDGET TURNIEJ/LIGA", value: { xApiKey: xApiKey, tournamenyid: tournamenyid, subtournamenyid: subtournamenyid } } })} checked={jsonCFG.view_widget ? jsonCFG.view_widget.id === 3 && jsonCFG.view_widget.value.tournamenyid === tournamenyid ? true : false : false} /> </div>
                          <Divider/>
                          <Grid >
                            <Grid.Row stretched>
                              
                              <Grid.Column width={8}>
                                <span style={{fontSize:11}}>Ustaw własne tło, najlepiej dodać plik graficzny o rozmiarach 1920x1080px i żeby miał jak najmniejszy rozmiar nie tracąc na jakości.</span>
                              <Input size='mini' fluid icon='image' value={jsonCFG.bgImage} onChange={(e,d) => {setjsonCFG({ ...jsonCFG, bgImage: d.value==="" ? null : d.value }) 
                            setimgUrlTGBackground(d.value==="" ? null : d.value)
                              }} />
                          
                              </Grid.Column>
                              <Grid.Column width={5}>
                              <AddImgMediaUser imgUrlTG={imgUrlTGBackground} setimgUrlTG={setimgUrlTGBackground} />
                              </Grid.Column>
                              <Grid.Column width={3} >
                              {jsonCFG.bgImage && jsonCFG.bgImage !== "" ? <MyImageLazy openView={true} maxHeight={100} src={jsonCFG.bgImage} /> : ""}
                              </Grid.Column>
                              
                            </Grid.Row>
                          </Grid>
                          <Divider/>
                          <div style={{ marginTop: 10, marginBottom: 20 }}>PARAMETRY DEDYKOWANE:</div>
                          <div ><ColorPicker format="hex" value={jsonCFG.bgcolor_tournament} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor_tournament: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.bgcolor_tournament} onChange={(e, d) => setjsonCFG({ ...jsonCFG, bgcolor_tournament: d.value })} /> Kolor tła</div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Użyj loga z Widgetu ogólnego zamiast ikony pucharu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_use_logo: d.checked })} checked={jsonCFG.tournament_use_logo ? true : false} /> </div>

                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Ciemny motyw strony" onChange={(e, d) => setjsonCFG({ ...jsonCFG, dark_tournament: d.checked })} checked={jsonCFG.dark_tournament ? true : false} /> </div>



                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Nazwa turnieju/ligi" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_name: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_name ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Nadchodzace mecze w lidze" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_nearest_matches: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_nearest_matches ? true : false} /> </div>

                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Video/Transmisja" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_video: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_video ? true : false} /> </div>

                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Lista uczestników" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_members_list: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_members_list ? true : false} /> </div>

                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Moduł zapisów do turnieju" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_registration: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_registration ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Moduł systemu turniejowego/ligowego" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_system: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_system ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Tabela końcowa" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_final_table: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_final_table ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Pokaż regulamin" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_show_rules: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_show_rules ? true : false} /> </div>
                         
                          
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Liga harmonogram widok komapktowy" onChange={(e, d) => setjsonCFG({ ...jsonCFG, tournament_league_view_compact: d.checked ? 1 : 0 })} checked={jsonCFG.tournament_league_view_compact ? true : false} /> </div>

                          <Button fluid style={{ marginTop: 10 }} onClick={() => setOrganizerWebsiteCfg()} color='green' loading={loading} disabled={loading}>ZAPISZ USTAWIENIA</Button>



                        </Grid.Column>
                        {xApiKey ?
                          <Grid.Column width={10} >
                            <Label color='grey' size='mini' style={{ marginBottom: 5 }}>
                              Podgląd: <a href={"/widget/v1/" + xApiKey + "/" + tournamenyid + "/" + subtournamenyid} target="_blank" rel="noopener noreferrer">ZOBACZ STRONĘ W NOWYM OKNIE</a>
                            </Label>
                            <iframe id="siteTGif" src={"/widget/v1/" + xApiKey + "/" + tournamenyid + "/" + subtournamenyid} width="100%" height={500} title="Turniej-Generator" frameborder="1"></iframe>
                          </Grid.Column>
                          : ""}
                      </Grid.Row>


                    </Grid>

                    {stepCreateIframe("https://turniej-generator.pl/widget/v1/" + xApiKey + "/" + tournamenyid + "/" + subtournamenyid)}
                  </> : <>
                    <Container textAlign='center' style={{ marginTop: 10 }}>
                      Proszę wybrać turniej/ligę <br />
                      <Checkbox style={{ marginBottom: 10, marginTop: 10 }} checked={checkboxTournamentList} onChange={(e, d) => setcheckboxTournamentList(d.checked)} toggle label="Wczytaj wszystkie turnieje" />
                      <Form>


                        <Form.Select
                          fluid
                          search
                          label={checkboxTournamentList ? "Wszystkie rozgrywki" : "Tylko nowe rozgrywki"}
                          options={checkboxTournamentList ? TournamentAll : Tournament}
                          value={tournamenyid}
                          onChange={(e, data) => ChangeTournamentBox(data)}
                          placeholder='Proszę wybrać rozgrywki...'
                        />

                        <Form.Group inline widths='equal'>
                          <Form.Select
                            fluid
                            search

                            placeholder='Proszę wybrać kategorię... lub przycisk Wybieram wszystkie kategorie'
                            options={SubTournament}
                            value={subtournamenyid}
                            onChange={(e, data) => {
                              setsubtournamenyid(data.value)
                            }}

                          />

                          <Button fluid disabled={tournamenyid === 0} onClick={() => setallCategory(1)}>Wybieram wszystkie kategorie</Button>
                        </Form.Group>
                      </Form>

                    </Container>
                  </>}

                </> : <Message style={{ marginTop: 20 }}
                  error
                  icon='info circle'
                  header='Brak aktywnej subskrypcji'
                  content='Moduł dostępny jest w Pakiecie Diamentowym lub Pakiecie Diamentowym Plus'
                />}
            </TabPanel>
            <TabPanel header={"WIDGET CYKL"} style={{ padding: 0, margin: 0 }} >
              {authContext && authContext.user && authContext.user.subscription_info && ((authContext.user.subscription_info.subscription_active === true && (authContext.user.subscription_info.subscription_extended >= 0)) || authContext.user.subscription_info.ignore_active_subscription === 1) ?
                <>
                  {tournamentCycleRow !== null && tournamentCycleRow.subtournamentName !== null ? <>
                    <Button fluid onClick={() => clearSelectTST()}>WYBIERZ INNY CYKL</Button>
                    <Grid stackable style={{ marginTop: 10 }}>
                      <Grid.Row >
                        <Grid.Column width={6}>
                          <div style={{ marginTop: 10, marginBottom: 20 }}><Checkbox toggle label="Wybierz ten widget do strony dedykowanej" onChange={(e, d) => setjsonCFG({ ...jsonCFG, view_widget: { id: 4, name: "WIDGET CYKL", value: { xApiKey: xApiKey, cycleId: tournamentCycleRow.cycleId, cycleName: tournamentCycleRow.cycleName, subtournamentName: tournamentCycleRow.subtournamentName } } })} checked={jsonCFG.view_widget ? jsonCFG.view_widget.id === 4 && jsonCFG.view_widget.value.cycleId === tournamentCycleRow.cycleId ? true : false : false} /> </div>

                          <div style={{ marginTop: 10, marginBottom: 20 }}>PARAMETRY DEDYKOWANE:</div>
                          <div ><ColorPicker format="hex" value={jsonCFG.bgcolor_cycle} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor_cycle: "#" + e.value })} /> <Input size='mini' style={{ width: 80 }} value={jsonCFG.bgcolor_cycle} onChange={(e, d) => setjsonCFG({ ...jsonCFG, bgcolor_cycle: d.value })} /> Kolor tła</div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Ciemny motyw strony" onChange={(e, d) => setjsonCFG({ ...jsonCFG, dark_cycle: d.checked })} checked={jsonCFG.dark_cycle ? true : false} /> </div>



                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Nazwa cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, cycle_name: d.checked ? 1 : 0 })} checked={jsonCFG.cycle_name ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Główna tabela cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, cycle_root_tab: d.checked ? 1 : 0 })} checked={jsonCFG.cycle_root_tab ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Wykres z liczbą uczestników" onChange={(e, d) => setjsonCFG({ ...jsonCFG, cycle_chart: d.checked ? 1 : 0 })} checked={jsonCFG.cycle_chart ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy którzy wygrali poszczególne turnieje w cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, cycle_members_winners: d.checked ? 1 : 0 })} checked={jsonCFG.cycle_members_winners ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy którzy byli na podium w poszczególnych turniejach w cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, cycle_members_podium: d.checked ? 1 : 0 })} checked={jsonCFG.cycle_members_podium ? true : false} /> </div>
                          <div style={{ marginTop: 10 }}><Checkbox toggle label="Lista turniejów zaliczonych do cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, cycle_tournaments: d.checked ? 1 : 0 })} checked={jsonCFG.cycle_tournaments ? true : false} /> </div>

                          <Button fluid style={{ marginTop: 10 }} onClick={() => setOrganizerWebsiteCfg()} color='green' loading={loading} disabled={loading}>ZAPISZ USTAWIENIA</Button>



                        </Grid.Column>
                        {xApiKey ?
                          <Grid.Column width={10} >
                            <Label color='grey' size='mini' style={{ marginBottom: 5 }}>
                              Podgląd: <a href={getlinkcycleWidget(xApiKey, tournamentCycleRow.cycleId, tournamentCycleRow.cycleName, tournamentCycleRow.subtournamentName)} target="_blank" rel="noopener noreferrer">ZOBACZ STRONĘ W NOWYM OKNIE</a>
                            </Label>
                            <iframe id="siteTGif" src={getlinkcycleWidget(xApiKey, tournamentCycleRow.cycleId, tournamentCycleRow.cycleName, tournamentCycleRow.subtournamentName)} width="100%" height={500} title="Turniej-Generator" frameborder="1"></iframe>
                          </Grid.Column>
                          : ""}
                      </Grid.Row>


                    </Grid>

                    {stepCreateIframe("https://turniej-generator.pl" + getlinkcycleWidget(xApiKey, tournamentCycleRow.cycleId, tournamentCycleRow.cycleName, tournamentCycleRow.subtournamentName))}
                  </> : <>
                    <Container textAlign='center' style={{ marginTop: 10 }}>

                      <Form>


                        <Form.Select
                          fluid
                          search
                          label={"Wybierz cykl"}
                          options={tournamentCycle}

                          onChange={(e, data) => ChangeCycleBox(data.value, 1)}
                          placeholder='Proszę wybrać cykl...'
                        />
                        {tournamentCycleRow && tournamentCycleRow.group_subtournaments === 0 ?
                          <Form.Select
                            fluid
                            search
                            label={"Wybierz kategorię"}
                            options={tournamentCycleList}
                            onChange={(e, data) => ChangeCycleBox(data.value, 2)}
                            placeholder='Proszę wybrać kategorię'
                          />
                          : ""}


                      </Form>

                    </Container>
                  </>}

                </> : <Message style={{ marginTop: 20 }}
                  error
                  icon='info circle'
                  header='Brak aktywnej subskrypcji'
                  content='Moduł dostępny jest w Pakiecie Diamentowym lub Pakiecie Diamentowym Plus'
                />}
            </TabPanel>

          </TabView>







          <Divider />
          <Container textAlign='center'>
            Jeżeli masz problem z osadzeniem strony, napisz do Nas.<br />
            A może potrzebujesz dedykowanego widoku strony? napisz do Nas.<br />
          </Container>


        </> : <Container textAlign='center'>
          <Input
            labelPosition='left'
            label="Wpisz nazwę strony"
            placeholder='Wpisz nazwę strony'
            value={pagename}
            type='text'
            onChange={(e, d) => setpagename(d.value)}
          />
          <br /> <br />
          {getInfoUrl(pagename)}

          <Button style={{ marginTop: 20 }} onClick={() => addOrganizerWebsite()} disabled={pagename === null || pagename === "" ? true : false}>UTWÓRZ DEDYKOWANĄ STRONĘ</Button>
        </Container>}
      </>}
      <CallBackTG />
    </>
  )
}

export default WebsiteConfigurator;