import React, { useState, useContext, useEffect } from 'react';
import { Modal,  Button, Form, Input } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../ConnectWS';
import AuthContext from '../context/authContext';
import { getModalHeaderTG } from '../myfunctions';

const ChangeAdditionalInfoForMembers = ({ dataAdditionalInfoForMembers, setdataAdditionalInfoForMembers }) => {
    const [open, setOpen] = useState(false);
    const [loading, setloading] = useState(false);

    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);

    const [formAddUsers, setFormAddUsers] = useState({
        additional_info: "",
    });
    const [additional_info, setadditional_info] = useState(null);

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps
        if (dataAdditionalInfoForMembers) {
            setOpen(true)
            setFormAddUsers(dataAdditionalInfoForMembers.dataRow)
            setadditional_info(JSON.parse(dataAdditionalInfoForMembers.dataRow.additional_info))

        }
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [dataAdditionalInfoForMembers])// eslint-disable-line react-hooks/exhaustive-deps


    const handleSave = async () => {


        let objjson = { ...formAddUsers, additional_info: additional_info }
    
        setloading(true)
        ConnectWS("/generator/editMemberTournament", authContext.token, { subtournament_id: dataAdditionalInfoForMembers.subTournamentId, ...objjson }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });


            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }
            setloading(false)
            setOpen(false)
            setdataAdditionalInfoForMembers(null)


        });



    };

    function closeModal() {
        setOpen(false)
        try {
            setdataAdditionalInfoForMembers(null)
        } catch (e) {

        }
    }

    function update_show_members_additional_info(i, value) {
        let jsonTmp = JSON.parse(JSON.stringify(additional_info))
        jsonTmp[i].value = value
        setadditional_info(jsonTmp)
      }

    return (
        <>
            <Modal open={open} onClose={() => closeModal()} >
                <Modal.Header>{getModalHeaderTG("Edycja informacji dodatkowych", "edit", "orange")}</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
                    <Form>
                        {additional_info  ?
                            additional_info.map((row, i) => row.enable ? row.type === "select" ? <Form.Select key={i}
                                value={row.value}
                                onChange={(event, data) => update_show_members_additional_info(i, data.value)}
                                label={row.name}
                                required={row.required === true ? true : false}
                                placeholder={row.name}
                                options={row.options}
                            /> : <Form.Field key={i}
                                control={Input}
                                value={row.value}
                                onChange={(event, data) => update_show_members_additional_info(i, data.value)}
                                label={row.name}
                                required={row.required === true ? true : false}
                                placeholder={row.name}
                                type={row.type}
                            /> : "") : ""}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => closeModal()}>Anuluj</Button>
                    <Button
                        color="green"
                        onClick={handleSave}
                        loading={loading}
                    >
                        Zapisz
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ChangeAdditionalInfoForMembers;
