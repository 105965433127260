import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Input } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import useAuth from '../../../../hooks/useAuth';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useToasts } from 'react-toast-notifications';
import { removeEmojis, removeSpecialChars, txtCase } from '../../../../myfunctions';
import GetEditorTG from '../../../../tools/GetEditorTG';

function MyTournamentEditUsers(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [loading, setloading] = useState(false);

  const [formAddUsers, setFormAddUsers] = useState({
    name: "",
    additional_info: "",
  });

  const [rte, setrte] = useState(null);


  useEffect(() => {

    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyTournamentEditUsers", props)
    }
    setFormAddUsers({ ...props.data })
    setrte(props.data.additional_info)

    return () => {   }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const addDBUsersToTournament = e => {
    e.preventDefault();
    let objjson = { ...formAddUsers, name: removeSpecialChars(formAddUsers.name).trim(), additional_info: rte ? rte : null }
    setloading(true)
    ConnectWS(props.editType === 3 ? "/generator/editMemberTeamName" : "/generator/editMemberTournament", authContext.token, { subtournament_id: props.subTournamentId, ...objjson }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
      setloading(false)
      props.setopenChangeUserData({ open: false, data: null })


    });

  }

  function surname2(name) {
    try {
      let words = name.split('-');
      let surnamenew = ""
      for (let i = 0; i < words.length; i++) {
        surnamenew = surnamenew + "" + txtCase(words[i]) + "-"
      }

      return surnamenew.slice(0, -1)
    } catch (e) {
      return name
    }
  }

  return (
    <>
      <Form size='tiny' onSubmit={addDBUsersToTournament}>
        {props.editType && (props.editType === 1 || props.editType === 3) ? <>
          {props.subTurnamentType === "USER" ?
            <>
              <Form.Field
                control={Input}
                value={formAddUsers.name}
                onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: surname2(txtCase(removeSpecialChars(removeEmojis(data.value)))) })}
                label='Imie i nazwisko'
                required={props.subTurnamentType === "USER" ? true : false}
                placeholder='Imie i nazwisko'

              />
            </>
            : <Form.Field
              control={Input}
              value={formAddUsers.name}
              required={props.subTurnamentType !== "USER" ? true : false}
              onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
              label='Nazwa drużyny'
              placeholder='Nazwa drużyny'

            />}
         
        </> : props.editType === 2 ? <div style={{width: '100%', maxWidth: '100%', overflowX: 'hidden'}}>
          <GetEditorTG useCfg={true} rte={rte} setrte={setrte} />
        
        </div> : ""}
        <Button loading={loading} disabled={loading} color='green' fluid type='submit'>ZAPISZ</Button>
      </Form>
    </>
  )
}

export default MyTournamentEditUsers;