import React, { useState, useEffect, useContext,lazy } from 'react';
import { Dropdown, Input, Table } from 'semantic-ui-react';

import AuthContext from '../../context/authContext';
import ShowImgAvatar from '../ShowImgAvatar';
import { getlinkplayerprofile } from '../../myfunctions';
import { lazyRetry } from '../../App';



const lazyWithRetry = (importFunc) => lazy(() => lazyRetry(importFunc));

const ProtokolPdfGenerator = lazyWithRetry(() => import('../ProtocolPdfGenerator/ProtokolPdfGenerator'));


function EditLeagueMatchDetailsExMatcheTs({ match_details_ex, setmatch_details_ex, members, row, onlyView,st }) {

    const authContext = useContext(AuthContext);

    const [team1Members, setteam1Members] = useState([])
    const [team2Members, setteam2Members] = useState([])

    useEffect(() => {

        if (match_details_ex) {
            createOptionsMembers()
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [match_details_ex])// eslint-disable-line react-hooks/exhaustive-deps


    function createOptionsMembers() {
        let team1 = []
        let team2 = []

        team1.push({
            key: 0,
            text: "Brak",
            value: null
        })
        team2.push({
            key: 0,
            text: "Brak",
            value: null
        })
        if (members && members.length > 0) {

            members.forEach((rowtmp) => {

                if (rowtmp.member_id === row.member1_id) {
                    if (rowtmp.members_list && rowtmp.members_list.length > 0) {
                        rowtmp.members_list.forEach((row1) => {
                            team1.push({
                                key: row1.member_id,
                                text: row1.member_name,
                                value: row1.member_id,
                                imgtg: row1.member_img_file,
                                //   image: { avatar: true, src: row1.member_img_file ? row1.member_img_file : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png' },
                            })
                        })
                    }
                } else if (rowtmp.member_id === row.member2_id) {
                    if (rowtmp.members_list && rowtmp.members_list.length > 0) {
                        rowtmp.members_list.forEach((row1) => {
                            team2.push({
                                key: row1.member_id,
                                text: row1.member_name,
                                value: row1.member_id,
                                imgtg: row1.member_img_file,
                                //     image: { avatar: true, src: row1.member_img_file ? row1.member_img_file : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png' },
                            })
                        })
                    }
                }
            })

            setteam1Members(team1)
            setteam2Members(team2)
        }
    }

    function getMembersData(co, id) {
        try {
            if (id) {
                let t = co.find(item => {
                    return item.value === id
                })
                if (t) {

                    return <figure style={{ float: "left", paddingRight: 10 }} className='imgPlayer'>
                        <ShowImgAvatar urlpic={t.imgtg} mtype={"0"} />
                        <figcaption>{getlinkplayerprofile(t.value, t.text, 2, 14, "_blank")}</figcaption></figure>
                }

            }
        } catch (e) {
            console.log(e)
        }
    }

    function createViewJson() {
        try {

            let data = []


            if (match_details_ex.matche_ts) {
                let m1 = 0;
                let m2 = 0;
                match_details_ex.matche_ts.forEach((element, i) => {

                    if (onlyView) {
                        data.push(<Table.Row>
                            <Table.Cell textAlign='center' style={{ width: 10 }} >
                                {element.type === 1 ? "S" : "D"}<span style={{ fontSize: 9 }}> {i + 1}</span>
                            </Table.Cell>

                            <Table.Cell textAlign='left' singleLine className={element.score_member1 > element.score_member2 ? 'winnerLeft' : element.score_member1 < element.score_member2 ? "loserLeft" : element.score_member1 === element.score_member2 && element.score_member1 !== null ? "drawLeft" : ""}
                                inverted={authContext.darkMode}
                            >{element.type === 1 ? getMembersData(team1Members, element.team1_member_id) : <><div>{getMembersData(team1Members, element.team1_member1_id)}</div> <div >{getMembersData(team1Members, element.team1_member2_id)}</div></>}</Table.Cell>
                            <Table.Cell textAlign='left' singleLine
                                inverted={authContext.darkMode} className={element.score_member1 < element.score_member2 ? 'winnerRight' : element.score_member1 > element.score_member2 ? "loserRight" : element.score_member1 === element.score_member2 && element.score_member1 !== null ? "drawRight" : ""}

                            >{element.type === 1 ? getMembersData(team2Members, element.team2_member_id) : <><div>{getMembersData(team2Members, element.team2_member1_id)} </div> <div > {getMembersData(team2Members, element.team2_member2_id)}</div></>}</Table.Cell>

                            <Table.Cell textAlign='center' singleLine style={{ width: 50, fontSize: 15 }}>
                                {element.score_member1 !== null ? <><b>{element.score_member1}:{element.score_member2}</b></> : ""}
                                {element.score_member1 !== null ? <span style={{ marginLeft: 5, fontSize: 13 }}>({element.score_member1 !== null && element.score_member1 > element.score_member2 ? m1 = m1 + 1 : m1}:{element.score_member1 !== null && element.score_member1 < element.score_member2 ? m2 = m2 + 1 : m2})</span> : ""}
                            </Table.Cell>

                            {element.details_score.map((row1, j) => <Table.Cell key={j} singleLine style={{ width: 50 }} textAlign='center'>
                                {row1.score_member1 !== null ? <>{row1.score_member1}:{row1.score_member2}</> : ""}

                            </Table.Cell>)}


                        </Table.Row>)
                    } else {
                        data.push(<Table.Row>
                            <Table.Cell textAlign='center' style={{ width: 10 }}>
                                {element.type === 1 ? "S" : "D"}<span style={{ fontSize: 9 }}> {i + 1}</span>
                            </Table.Cell>

                            <Table.Cell textAlign='center'>{element.type === 1 ? <Dropdown
                                placeholder='Wybierz zawodnika'
                                fluid
                                selection
                                value={element.team1_member_id}
                                onChange={(e, d) => updateJsonAddMembers(d.value, i, 1, 1, element.type)}
                                options={team1Members}
                            /> : <><Dropdown
                                placeholder='Wybierz zawodnika'
                                fluid
                                value={element.team1_member1_id}
                                selection
                                onChange={(e, d) => updateJsonAddMembers(d.value, i, 1, 1, element.type)}
                                options={team1Members}
                            /><Dropdown
                                    placeholder='Wybierz zawodnika'
                                    fluid
                                    selection
                                    value={element.team1_member2_id}
                                    onChange={(e, d) => updateJsonAddMembers(d.value, i, 1, 2, element.type)}
                                    options={team1Members}
                                /></>} </Table.Cell>
                            <Table.Cell textAlign='center'>{element.type === 1 ? <Dropdown
                                placeholder='Wybierz zawodnika'
                                fluid
                                selection
                                value={element.team2_member_id}
                                onChange={(e, d) => updateJsonAddMembers(d.value, i, 2, 1, element.type)}
                                options={team2Members}
                            /> : <><Dropdown
                                placeholder='Wybierz zawodnika'
                                fluid
                                selection
                                value={element.team2_member1_id}
                                onChange={(e, d) => updateJsonAddMembers(d.value, i, 2, 1, element.type)}
                                options={team2Members}
                            /><Dropdown
                                    placeholder='Wybierz zawodnika'
                                    fluid
                                    selection
                                    value={element.team2_member2_id}
                                    onChange={(e, d) => updateJsonAddMembers(d.value, i, 2, 2, element.type)}
                                    options={team2Members}
                                /></>} </Table.Cell>
                            <Table.Cell textAlign='center' singleLine style={{ width: 60 }}>
                                <Input style={{ width: 70 }} type="number" value={element.score_member1} onChange={(e, d) => updateJsonScore(d.value, i, "score_member1")} />:<Input style={{ width: 70 }} value={element.score_member2} onChange={(e, d) => updateJsonScore(d.value, i, "score_member2")} type="number" />
                            </Table.Cell>
                            {element.details_score.map((row, j) => <Table.Cell key={j} singleLine style={{ width: 60 }} textAlign='center'>
                                <Input style={{ width: 70 }} type="number" value={row.score_member1} onChange={(e, d) => updateJsonScoreDetails(d.value, i, j, "score_member1")} />:<Input style={{ width: 70 }} type="number" value={row.score_member2} onChange={(e, d) => updateJsonScoreDetails(d.value, i, j, "score_member2")} />
                            </Table.Cell>)}


                        </Table.Row>)
                    }
                })
            }
            return data
        } catch (e) {
            console.log(e)
        }
    }

    function updateJsonAddMembers(value, nrRow, memberNr, memberNrInTeam, type) {
        //memberNrInTeam 1, 2 w zależności type czy to debel czy singiel
        try {
            let tmpjson = { ...match_details_ex }
            value = Number(value) > 0 ? Number(value) : null
            if (memberNr === 1 && type === 1) {
                // team1 oraz singiel
                tmpjson.matche_ts[nrRow].team1_member_id = value
            } else if (memberNr === 1 && type === 2) {
                // team1 oraz debel
                if (memberNrInTeam === 1) {
                    tmpjson.matche_ts[nrRow].team1_member1_id = value
                } else {
                    tmpjson.matche_ts[nrRow].team1_member2_id = value
                }

            } else if (memberNr === 2 && type === 1) {
                // team1 oraz singiel
                tmpjson.matche_ts[nrRow].team2_member_id = value
            } else if (memberNr === 2 && type === 2) {
                // team1 oraz debel
                if (memberNrInTeam === 1) {
                    tmpjson.matche_ts[nrRow].team2_member1_id = value
                } else {
                    tmpjson.matche_ts[nrRow].team2_member2_id = value
                }

            }

            setmatch_details_ex(tmpjson)
        } catch (e) {
            console.log(e)
        }

        //console.log(tmpjson,value,nrRow,memberNr,memberNrInTeam,type)
    }


    function updateJsonScore(value, nrRow, mtype) {
        try {
            let tmpjson = { ...match_details_ex }
            value = value === "" ? null : Number(value) >= 0 ? Number(value) : null
            tmpjson.matche_ts[nrRow][mtype] = value
            setmatch_details_ex(tmpjson)
        } catch (e) {
            console.log(e)
        }
    }

    function updateJsonScoreDetails(value, nrRow, nrRow1, mtype) {
        try {
            let tmpjson = { ...match_details_ex }
            value = value === "" ? null : Number(value) >= 0 ? Number(value) : null
            tmpjson.matche_ts[nrRow].details_score[nrRow1][mtype] = value
            setmatch_details_ex(tmpjson)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div style={{ marginBottom: 20 }}>
            {team1Members && team2Members && st && onlyView===0 ? <ProtokolPdfGenerator
                teamNameA={row.member1_name}
                playersTeamA={team1Members.map((p) => ({
                    member_id: p.value, member_name: p.text
                }))}
                teamNameB={row.member2_name}
                playersTeamB={team2Members.map((p) => ({
                    member_id: p.value, member_name: p.text
                }))}
                pdfUrl={st.protocol_url_match_pdf}
                fillMatchPdf={st.fill_match_pdf ? st.fill_match_pdf : null}
            />
                : ""}
            {match_details_ex && match_details_ex.matche_ts ?
                <div style={{ overflowX: "auto", width: "100%" }} >
                    <Table style={{ fontSize: 12 }} celled striped compact unstackable >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ width: 10, lineHeight: "12px" }} textAlign='center'></Table.HeaderCell>

                                <Table.HeaderCell singleLine style={{ width: 100, lineHeight: "12px" }} textAlign='center'>
                                    <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member1_img_file} mtype={"TEAM"} /><figcaption> {row.member1_name}</figcaption></figure>
                                </Table.HeaderCell>
                                <Table.HeaderCell singleLine style={{ width: 100, lineHeight: "12px" }} textAlign='center'>
                                    <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member2_img_file} mtype={"TEAM"} /><figcaption> {row.member2_name}</figcaption></figure>
                                </Table.HeaderCell>

                                <Table.HeaderCell width={1} style={{ lineHeight: "12px" }} textAlign='center' singleLine>Wynik</Table.HeaderCell>
                                {match_details_ex.matche_ts[0].details_score.map((row, i) => <Table.HeaderCell style={{ width: 30 }} key={i} textAlign='center' singleLine>Set {row.nr}</Table.HeaderCell>)}


                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createViewJson()}
                        </Table.Body>
                    </Table>
                </div>
                : ""}


            
        </div>
    )
}

export default EditLeagueMatchDetailsExMatcheTs;