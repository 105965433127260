import React, { useState, useContext, useEffect } from 'react';
import { Modal, Dropdown, Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../ConnectWS';
import AuthContext from '../context/authContext';
import { getModalHeaderTG } from '../myfunctions';

const ChangeGroupForParticipant = ({ dataChangeGroupForParticipant, setdataChangeGroupForParticipant }) => {
    const [open, setOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);
    const [groupOptions, setgroupOptions] = useState([]);


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps
        if (dataChangeGroupForParticipant) {
            setOpen(true)
            setgroupOptions(dataChangeGroupForParticipant.groups.map(group => ({
                key: group.subtournament_group_id,
                text: group.group_name,
                value: group.subtournament_group_id
            })))
        }
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [dataChangeGroupForParticipant])// eslint-disable-line react-hooks/exhaustive-deps


    const handleSave = async () => {
        if (!selectedGroup) {
            addToast('Wybierz grupę.', { appearance: 'error' });
            return;
        }

        setLoading(true);



        ConnectWS("/generator/moveMemberToAnotherGroup", authContext.token, { subtournament_group_member_id: dataChangeGroupForParticipant.subtournament_group_member_id, subtournament_group_id: dataChangeGroupForParticipant.subtournament_group_id, new_subtournament_group_id: selectedGroup }, function (response, logout) {

            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                setOpen(false)
                try {
                    setdataChangeGroupForParticipant(null)
                } catch (e) {

                }
            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }
            setLoading(false);

        });



    };

    function closeModal() {
        setOpen(false)
        try {
            setdataChangeGroupForParticipant(null)
        } catch (e) {

        }
    }

    return (
        <>
            <Modal open={open} onClose={() => closeModal()} >
                <Modal.Header>{getModalHeaderTG("Zmień grupę uczestnika", "exchange", "orange")}</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
                    <Dropdown
                        placeholder="Wybierz grupę"
                        fluid
                        selection
                        options={groupOptions}
                        value={selectedGroup}
                        onChange={(e, { value }) => setSelectedGroup(value)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => closeModal()}>Anuluj</Button>
                    <Button
                        color="blue"
                        onClick={handleSave}
                        loading={loading}
                        disabled={!selectedGroup}
                    >
                        Zapisz
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ChangeGroupForParticipant;
